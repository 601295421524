@font-face{
  font-family:"Dosis";
  src: local("Dosis"),
  url("./assets/fonts/Dosis-VariableFont_wght.ttf") format("truetype");
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 4px black;
  opacity: 0.1;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 3px solid black;
}

::-webkit-scrollbar-thumb:hover {
  background:black;
}

.App {
  font-family:"Dosis";

  background-color: black;
  color: white;
}

.light {
    background-color: white;
    color: black;
}

.footer {
  position:absolute;
  bottom: 0;
  height: 30px;
  font-size: 80%;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.hover-pointer {
  cursor: pointer;
}

.App hr{
  width: 33%;
  margin: 0 auto;
}

.App a {
  text-decoration: underline;
  color: inherit; 
}


.left-column{
  float: left;
  width: 80%;
  height: 100vh;
  text-align: center;
  padding: 50px;
  overflow-y: scroll;
}

.right-column{
  float: left;
  width: 20%;
  padding: 10px;
  word-wrap: break-word;
  height: 100vh;
  border-left: 1px solid;
}

.menu-item {
  font-size: 200%;
}

.menu-item-first{
  margin-top: 65%;
}

.menu-item:hover{
  
}

@media only screen and (max-width: 900px) {

  .left-column{
    float: left;
    width: 70%;
    padding: 10px;
  }
  
  .right-column{
    float: left;
    width: 30%;
    padding: 10px;
    word-wrap: break-word;
  }

  .menu-item {
    font-size: 150%;
  }
}